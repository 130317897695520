import classnames from 'classnames'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { type AsyncState } from 'react-use/lib/useAsyncFn'
import * as Api from 'src/api'
import { type CommonError } from 'src/api'
import { ArrowIcon } from 'src/assets/icons/customIcons/Arrow'
import { Checkmark } from 'src/assets/icons/customIcons/Checkmark'
import { Settings } from 'src/assets/icons/customIcons/Settings'
import { BellIcon } from 'src/assets/icons/customIcons/header-icons/Bell'
import { actionProps, errorsToArray } from 'src/helpers/fns'
import { useDropdown } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import ErrorIcon from 'src/imgs/error_icon.svg'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import { NotificationsLink } from './NotificationsLink'

export default function NotificationsIcon(): React.ReactElement | null {
  const locale = useLocale()
  const t = useTranslatable()
  const userState = useUserState()
  const { show, setShow, ref, id } = useDropdown<HTMLLIElement>()

  const headers = useAuthenticatedHeaders()

  const [notifications, fetchNotifications] = useAsyncFn(async () => {
    return await Api.getNotifications({
      headers,
      query: {
        page: 1,
        perPage: 10,
      },
    })
  }, [])

  React.useEffect(() => {
    if (show) {
      void fetchNotifications()
    }
  }, [fetchNotifications, show])

  return (
    <li className={classnames('relative', { show })} ref={ref}>
      <a
        id={id}
        role="button"
        aria-haspopup="true"
        aria-expanded={show ? 'true' : 'false'}
        {...actionProps(() => setShow(!show))}
        title={t('notification:notifications')}
        data-testid="notificationsHeaderButton"
      >
        <BellIcon />
        {userState.data?.notificationsUnread != null && userState.data.notificationsUnread > 0 && (
          <div className="absolute !top-[-7px] right-[-7px] flex size-[16px] items-center justify-center rounded-full bg-lightSecondaryWarning">
            <span className="text-tiny text-white">
              {userState.data?.notificationsUnread != null &&
              userState.data.notificationsUnread > 0 &&
              userState.data.notificationsUnread > 99
                ? '99+'
                : userState.data?.notificationsUnread != null && userState.data.notificationsUnread <= 99
                  ? userState.data.notificationsUnread
                  : null}
            </span>
          </div>
        )}
      </a>
      <div
        className={classnames(
          'notifications-popup absolute right-0 mt-1 hidden cursor-default rounded-xl bg-primaryWhite shadow-3xl',
          {
            show,
          }
        )}
        aria-labelledby={id}
      >
        <ul className="mb-3 flex items-center justify-between border-b border-borderGrey p-2.5">
          {notifications.value?.data.length === 0 ? (
            ''
          ) : userState.data?.notificationsUnread != null && userState.data.notificationsUnread > 0 ? (
            <li className="flex">
              <a
                className="flex rounded-[18px] p-[10px] hover:bg-card"
                {...actionProps(() => {
                  void Api.patchNotificationsMarkAllAsRead({
                    headers,
                  }).then(() => {
                    void fetchNotifications()
                    void userState.mutate()
                  })
                })}
                data-testid="markAllAsReadHeaderButton"
              >
                <Checkmark />
                <span className="ml-2 text-xs text-lightPrimaryIconText">{t('common:mark_as_read')}</span>
              </a>
            </li>
          ) : (
            <li className="flex rounded-[18px] bg-card p-[10px]">
              <span className="opacity-50">
                <Checkmark />
              </span>
              <span className="ml-2 rounded-[18px] bg-card text-xs text-primaryTextColor opacity-50">
                {t('common:mark_as_read')}
              </span>
            </li>
          )}
          <li className="flex w-full justify-end">
            <Link
              className="flex rounded-full p-[3px] hover:bg-card"
              to={`/${locale}/profile/settings/notifications`}
              onClick={() => void setShow(false)}
              data-testid="notificationsSettingButton"
            >
              <Settings />
            </Link>
          </li>
        </ul>
        <div>
          <NotificationsContent notifications={notifications} setShow={setShow} />
        </div>
        <Link to={`/${locale}/notifications`} onClick={() => void setShow(false)} data-testid="allNotificationsButton">
          <div className="mt-4 flex items-center border-t border-borderGrey p-3 text-title text-primaryBlueLink">
            {t('notification:all_notifications')}
            <span className="ml-3 fill-primaryBlue">
              <ArrowIcon />
            </span>
          </div>
        </Link>
      </div>
    </li>
  )
}

interface Props {
  readonly notifications: AsyncState<Api.getNotificationsOk>
  readonly setShow: (arg0: boolean) => void
}

function NotificationsContent({ notifications, setShow }: Props): React.ReactElement | null {
  const t = useTranslatable()

  if (notifications.error != null) {
    const error = notifications.error as any as CommonError

    return (
      <NoContent
        image={ErrorIcon}
        header={t('error:an_error_occurred')}
        errors={error.type === 'ErrorsObject' ? errorsToArray(error.errors) : []}
      />
    )
  }

  if (notifications.value == null) {
    return <Loader className="flex" style={{ margin: 'auto' }} />
  }

  return (
    <div className="max-h-[500px] overflow-y-auto px-4 pt-1">
      {notifications.value.data.length > 0 ? (
        notifications.value.data.map((notification) => (
          <div key={notification.id} onClick={() => setShow(false)}>
            <NotificationsLink notification={notification} />
          </div>
        ))
      ) : (
        <p>{t('error:notifications_not_found')}</p>
      )}
    </div>
  )
}
