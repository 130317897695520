import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { type UserAnswerOption } from 'src/api'
import * as SurveyTable from 'src/tailwind/components/SurveyTable'
import * as Yup from 'yup'

interface Option {
  readonly id: number
  readonly title: string // გამოდის ჩეკბოქსის ლეიბლად
  readonly customAllowed: boolean
  readonly fcolor: string
  readonly bcolor: string
}

interface RadioType {
  readonly id: number
  readonly type: number
  readonly title: string
  readonly required: boolean
  readonly fcolor: string
  readonly bcolor: string
  readonly options: Option[]
}

interface QuestionTypeRadioTypeValue {
  readonly optionId: number
}

interface TypeRadioTypeProps {
  readonly question: RadioType
  readonly name: string
  readonly index: number
}

export function SurveyRadioSchema(message: string, isRequired: boolean): Yup.Schema {
  if (!isRequired) return Yup.mixed()
  return Yup.array(Yup.object({ optionId: Yup.string().required(message) })).required(message)
}

export default function SurveyRadioInput({ question, name, index }: TypeRadioTypeProps): React.ReactElement {
  const { errors, isValid, submitCount } = useFormikContext<{ [k: string]: readonly UserAnswerOption[] | undefined }>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<undefined | readonly QuestionTypeRadioTypeValue[]>({ name })

  const labelPrefix = `label-${React.useId()}`

  const value = React.useRef(
    React.useMemo(() => {
      return field.value?.[0]?.optionId ?? null
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  function updateValue(): void {
    if (value.current == null) {
      void helpers.setValue([])
    } else {
      void helpers.setValue([
        {
          optionId: value.current,
        },
      ])
    }
  }
  return (
    <div data-testid={name}>
      <div className="mt-2 flex text-primaryTextColor" data-testid="questionTitle">
        <div className="mr-1">{index}.</div>
        <div className="font-bold text-primaryTextColor">
          {question.title}
          {question.required && <span className="ml-1 text-primaryRed">*</span>}
        </div>
      </div>
      <SurveyTable.SurveyTable className="border-0">
        <SurveyTable.Thead />
        <SurveyTable.Tbody>
          {question.options.map((option) => (
            <SurveyTable.Tr key={option.id} data-testid={`radioOption/${option.id}`}>
              <SurveyTable.Td>
                <div className="flex items-center">
                  <input
                    className="size-4 bg-gray-100"
                    id={`${labelPrefix}-${option.id}`}
                    name={`${labelPrefix}-${option.id}`}
                    type="radio"
                    value={`${labelPrefix}-${option.id}`}
                    checked={value.current === option.id}
                    onChange={() => {
                      value.current = option.id
                      updateValue()
                    }}
                  />
                  <label htmlFor={`${labelPrefix}-${option.id}`} style={{ marginLeft: 10, marginTop: '1.5px' }}>
                    {option.title}
                  </label>
                </div>
              </SurveyTable.Td>
            </SurveyTable.Tr>
          ))}
        </SurveyTable.Tbody>
      </SurveyTable.SurveyTable>
      <div className="h-[30px] px-[12px] py-0 text-sm text-primaryRed" data-testid="error">
        {!isValid && submitCount > 0 ? errors[`field-${question.id}`] : null}
      </div>
    </div>
  )
}
