import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { type UserAnswerOption } from 'src/api'
import { useTheme } from 'src/state/providers/Theme'
import * as Yup from 'yup'

interface Option {
  readonly id: number
  readonly title: string // გამოდის ჩეკბოქსის ლეიბლად
  readonly customAllowed: boolean
  readonly fcolor: string
  readonly bcolor: string
}

interface CheckboxesOtherType {
  readonly id: number
  readonly type: number
  readonly title: string
  readonly required: boolean
  readonly fcolor: string
  readonly bcolor: string
  readonly options: readonly Option[]
}

interface QuestionTypeCheckboxValue {
  readonly optionId: number
  readonly value: null | string
}

interface TypeCheckboxesOtherProps {
  readonly question: CheckboxesOtherType
  readonly name: string
  readonly index: number
}

export function SurveyCheckboxOtherSchema(message: string, isRequired: boolean): Yup.Schema {
  if (!isRequired) return Yup.mixed()

  return Yup.array(
    Yup.object({
      value: Yup.lazy((value) => {
        if (value === null) {
          return Yup.mixed().nullable()
        }
        if (value === undefined) {
          return Yup.mixed()
        }

        return Yup.string().required(message)
      }),
    })
  )
    .min(1, message)
    .required(message)
    .transform((val) => {
      const result = val.map((o: any) => {
        if ('value' in o) {
          if (o.value === undefined) {
            return { ...o, value: '' }
          }
        }
        return o
      })
      return result
    })
}

export default function SurveyCheckboxOtherInput({
  question,
  name,
  index,
}: TypeCheckboxesOtherProps): React.ReactElement {
  const { errors, isValid, submitCount } = useFormikContext<{ [k: string]: readonly UserAnswerOption[] | undefined }>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<undefined | readonly QuestionTypeCheckboxValue[]>({ name })
  const theme = useTheme()

  const labelPrefix = `label-${React.useId()}`

  const values = React.useRef(
    React.useMemo(() => {
      return Object.fromEntries(
        question.options.map((o) => {
          const value = field.value?.find((v) => v.optionId === o.id)
          return [
            `checkbox-${o.id}`,
            {
              checked: value != null,
              text: value?.value ?? '',
            },
          ]
        })
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  function updateValue(): void {
    void helpers.setValue(
      question.options.flatMap((option) => {
        const value = values.current[`checkbox-${option.id}`]!

        if (value.checked) {
          return {
            optionId: option.id,
            value: option.customAllowed ? value.text : null,
          }
        }

        return []
      })
    )
  }

  return (
    <div data-testid={name}>
      <div
        className="flex w-fit px-[8px] py-[2px]"
        style={{
          color: `${question.fcolor ?? (theme === 'dark' ? '#e5e5e5' : 'initial')}`,
          backgroundColor: `${question.bcolor ?? 'initial'}`,
        }}
        data-testid="questionTitle"
      >
        <div className="mr-2">{index}.</div>
        <div className="font-bold">
          {question.title}
          {question.required && <span className="pl-1 text-lightSecondaryWarning">*</span>}
        </div>
      </div>
      <table className="mb-2 table table-auto">
        <thead />
        <tbody>
          {question.options.map((option) => (
            <tr key={option.id} data-testid={`checkboxOtherOption/${option.id}`}>
              <td className="py-[10px]">
                <div
                  className="relative w-fit px-[8px] py-px"
                  style={{
                    color: `${option.fcolor ?? (theme === 'dark' ? '#e5e5e5' : 'initial')}`,
                    backgroundColor: `${option.bcolor ?? 'initial'}`,
                  }}
                >
                  <input
                    id={`${labelPrefix}-${option.id}`}
                    name={`${labelPrefix}-${option.id}`}
                    type="checkbox"
                    checked={values.current[`checkbox-${option.id}`]!.checked}
                    onChange={(e) => {
                      values.current[`checkbox-${option.id}`]!.checked = e.target.checked
                      updateValue()
                    }}
                  />
                  <label htmlFor={`${labelPrefix}-${option.id}`} style={{ marginLeft: 10, marginTop: '1.4px' }}>
                    {option.title}
                  </label>
                  {option.customAllowed ? (
                    <input
                      type="text"
                      className="absolute left-full right-[-7px] h-[120%] w-[190px]"
                      value={values.current[`checkbox-${option.id}`]!.text}
                      onChange={(e) => {
                        values.current[`checkbox-${option.id}`]!.text = e.target.value
                        updateValue()
                      }}
                      disabled={!values.current[`checkbox-${option.id}`]!.checked}
                      style={{
                        maxWidth: 320,
                        marginLeft: 20,
                      }}
                    />
                  ) : null}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="h-[30px] px-[12px] py-0 text-sm text-lightSecondaryWarning" data-testid="error">
        {!Array.isArray(errors[`field-${question.id}`]) && submitCount > 0 && !isValid
          ? errors[`field-${question.id}`]
          : Array.isArray(errors[`field-${question.id}`]) && submitCount > 0 && !isValid
            ? (errors[`field-${question.id}`] as any)[(errors[`field-${question.id}`] as any).length - 1].value
            : null}
      </div>
    </div>
  )
}
