import * as FreeBrandsSvgIcons from '@fortawesome/free-brands-svg-icons'
import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useWindowSize } from 'react-use'
import { LoudspeakerIcon } from 'src/assets/icons/customIcons/header-icons/Loudspeaker'
import { AchievmentIcon } from 'src/assets/icons/customIcons/menu-icons/Achievment'
import { CalendarIcon } from 'src/assets/icons/customIcons/menu-icons/Calendar'
import { CatalogIcon } from 'src/assets/icons/customIcons/menu-icons/Catalog'
import { FeedbackIcon } from 'src/assets/icons/customIcons/menu-icons/Feedback'
import { HomeIcon } from 'src/assets/icons/customIcons/menu-icons/Home'
import { MycoursesIcon } from 'src/assets/icons/customIcons/menu-icons/Mycourses'
import PortfolioIcon from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { ProgramsIcon } from 'src/assets/icons/customIcons/menu-icons/Programs'
import { RatingIcon } from 'src/assets/icons/customIcons/menu-icons/Rating'
import { RecomendationsIcon } from 'src/assets/icons/customIcons/menu-icons/Recomendations'
import { ResumeIcon } from 'src/assets/icons/customIcons/menu-icons/Resume'
import { ScheduleIcon } from 'src/assets/icons/customIcons/menu-icons/Schedule'
import { SelectedCoursesIcon } from 'src/assets/icons/customIcons/menu-icons/SelectedCourses'
import { StatementsIcon } from 'src/assets/icons/customIcons/menu-icons/Statements'
import env from 'src/helpers/env'
import { useAuth } from 'src/hooks/auth/auth'
import { useTranslatable } from 'src/hooks/locale/utils'
import { sidebarStorage, useSidebar } from 'src/hooks/sidebar'
import { useUserState } from 'src/hooks/userState'
import MenuItem from 'src/views/components/MenuItem'
import ArgusLogo from 'src/views/includes/ArgusLogo'
import LocaleSwitcher from 'src/views/includes/header/LocaleSwitcher'

export function Sidebar(): JSX.Element | null {
  const t = useTranslatable()
  const { width } = useWindowSize()
  const userState = useUserState()
  const isSidebarOpen = useSidebar()

  const auth = useAuth()

  if (auth.state !== 'Authenticated') return null

  const profileStatus = auth.profile.profileStatus!.active
  const profileType = auth.profile.type

  return (
    <div
      className={classNames(
        'absolute left-0 z-[11] flex text-primaryTextColor duration-[300ms] ease-in-out sm:z-50 md:sticky lg:top-0 xxs:z-[50] xs:z-[50]',
        {
          'left-[-100vw]': !isSidebarOpen && width < 768,
        }
      )}
      data-testid="sidebar"
    >
      <nav
        className={classNames(
          `h-[100vh] flex-col items-start justify-start bg-nav pt-[18px] shadow-[0px_1px_14px_rgba(0,0,0,0.2)] duration-[800ms] ease-in-out ${
            isSidebarOpen ? 'w-[260px]' : 'w-[52px]'
          } `,
          { 'mobile-mode-nav': !isSidebarOpen }
        )}
      >
        <div className="flex items-center">
          <button
            className="group relative ml-3 flex h-[45px] flex-col items-center justify-center rounded-full px-[12px] pt-[6px] duration-[800ms] ease-in-out hover:bg-white md:hidden xxs:hidden xs:hidden"
            onClick={() => sidebarStorage.setValue(!isSidebarOpen)}
          >
            <div className="mb-[4px] h-px w-[15px] translate-x-[-6px] rotate-[-35deg] bg-primaryTextColor duration-300 ease-in-out" />
            <div className="mb-[4px] h-px w-[24px] bg-primaryTextColor duration-[800ms] ease-in-out" />
            <div className="mb-[4px] h-px w-[15px] translate-x-[-6px] rotate-[35deg] bg-primaryTextColor duration-300 ease-in-out" />
          </button>
          <ArgusLogo isSidebarOpen={isSidebarOpen} />
        </div>
        {profileType === 'UserProfileStudent' && (
          <ul className="mt-[17%] h-[70vh] max-h-[700px] w-full list-none overflow-y-auto overflow-x-hidden pl-0 xxs:h-[40vh] xxs:overflow-y-scroll xs:h-[60vh] xs:overflow-y-scroll">
            <MenuItem icon={<HomeIcon />} title={t('system:home_page')} defaultPath="student" end />
            {profileStatus && (
              <>
                <MenuItem
                  icon={<SelectedCoursesIcon />}
                  title={t('course:selected_courses')}
                  defaultPath="student/choices"
                />
                <MenuItem icon={<MycoursesIcon />} title={t('course:my_courses')} defaultPath="student/courses" />
                <MenuItem icon={<CatalogIcon />} title={t('course:courses_catalog')} defaultPath="student/catalog" />
                <MenuItem icon={<ProgramsIcon />} title={t('program:my_programs')} defaultPath="student/programs" />
                <MenuItem icon={<CalendarIcon />} title={t('calendar:calendar')} defaultPath="student/calendar" />
                <MenuItem icon={<ScheduleIcon />} title={t('schedule:my_schedule')} defaultPath="student/schedule" />
              </>
            )}
            <MenuItem icon={<StatementsIcon />} title={t('statement:statements')} defaultPath="student/statements" />
            {userState.data?.regime.functionalIsLimited === false && (
              <MenuItem icon={<LoudspeakerIcon />} title={t('news:news')} defaultPath="news" />
            )}
            <MenuItem
              icon={<ResumeIcon />}
              title={t('resume:resume')}
              defaultPath="student/resume"
              isStudent
              pages={[
                {
                  link: 'student/resume/personal',
                  title: t('person:personal_information'),
                },
                {
                  link: 'student/resume/education',
                  title: t('resume:education'),
                },
                {
                  link: 'student/resume/work',
                  title: t('resume:working_experience'),
                },
                {
                  link: 'student/resume/trainings',
                  title: t('resume:trainings_seminars_workshops'),
                },
                {
                  link: 'student/resume/skills',
                  title: t('resume:personal_skills'),
                },
                {
                  link: 'student/resume/additional',
                  title: t('resume:additional_info'),
                },
                {
                  link: 'student/resume/summary',
                  title: t('resume:resume_summary'),
                },
              ]}
            />
            <MenuItem icon={<AchievmentIcon />} title={t('achievement:achievement_sheet')} defaultPath="student/card" />

            {env.APP_ENV !== 'PRODUCTION' &&
              userState.data?.regime.functionalIsLimited === false &&
              userState.data?.portfolioExists && (
                <MenuItem
                  icon={<PortfolioIcon />}
                  title={t('portfolio:portfolio')}
                  defaultPath="student/portfolio"
                  isStudent
                  betaSignIcon={true}
                  pages={[
                    {
                      link: 'student/portfolio/sections',
                      title: t('portfolio:general'),
                    },
                  ]}
                />
              )}

            <MenuItem
              icon={<RecomendationsIcon />}
              title={t('recommendation:recommendations')}
              defaultPath="student/recommendations"
            />
            <MenuItem icon={<RatingIcon />} title={t('person:rating')} defaultPath="student/rating" />
            <MenuItem icon={<FeedbackIcon />} title={t('feedback:feedback')} defaultPath="student/feedbacks" />
          </ul>
        )}
        {profileType === 'UserProfileLecturer' && (
          <ul className="mt-[17%] w-full list-none pl-0">
            <MenuItem icon={<HomeIcon />} title={t('system:home_page')} defaultPath="lecturer" end />
            <MenuItem
              icon={<CatalogIcon />}
              title={t('course:learning_courses')}
              defaultPath="lecturer/courses/teaches"
            />
            <MenuItem
              icon={<StatementsIcon />}
              title={t('syllabus:syllabuses')}
              defaultPath="lecturer/courses/manage"
            />
            <MenuItem icon={<CalendarIcon />} title={t('calendar:calendar')} defaultPath="lecturer/calendar" />
            <MenuItem icon={<ScheduleIcon />} title={t('schedule:my_schedule')} defaultPath="lecturer/schedule" />

            {env.APP_ENV !== 'PRODUCTION' &&
              userState.data?.regime.functionalIsLimited === false &&
              userState.data?.portfolioExists && (
                <MenuItem
                  icon={<PortfolioIcon />}
                  title={t('portfolio:portfolio')}
                  defaultPath="lecturer/portfolio/sections"
                  betaSignIcon={true}
                  pages={[
                    {
                      link: 'lecturer/portfolio/sections',
                      title: t('portfolio:general'),
                    },
                  ]}
                />
              )}

            <MenuItem icon={<FeedbackIcon />} title={t('feedback:feedback')} defaultPath="lecturer/feedbacks" />
          </ul>
        )}
        {profileType === 'UserProfileAdministrator' && (
          <ul className="mt-[17%] w-full list-none pl-0">
            <MenuItem
              icon={<FontAwesomeIcon icon={FreeBrandsSvgIcons.faBandcamp} />}
              title={t('system:home_page')}
              defaultPath="administrator"
              end
            />
            <MenuItem
              icon={<FontAwesomeIcon icon={ProRegularSvgIcons.faChartPieAlt} />}
              title={t('statistics:statistics')}
              defaultPath="administrator/statistics"
              pages={[
                {
                  link: 'administrator/statistics',
                  title: t('statistics:statistics'),
                },
                {
                  link: 'administrator/statistics/choices',
                  title: t('statistics:statistics_choices'),
                },
              ]}
            />
            <MenuItem
              icon={<FontAwesomeIcon icon={ProRegularSvgIcons.faUsers} />}
              title={t('system:users')}
              defaultPath="administrator/users"
              pages={[
                {
                  link: 'administrator/users/all',
                  title: t('system:users'),
                },
                {
                  link: 'administrator/users/administrators',
                  title: t('system:admins'),
                },
                {
                  link: 'administrator/users/lecturers',
                  title: t('lecturer:lecturers'),
                },
                {
                  link: 'administrator/users/students',
                  title: t('student:students'),
                },
              ]}
            />
          </ul>
        )}
        <LocaleSwitcher mobile={isSidebarOpen} />
      </nav>
      <div className="block h-screen w-[calc(98vw-260px)] md:hidden" onClick={() => sidebarStorage.setValue(false)} />
    </div>
  )
}
