import classnames from 'classnames'
import { Formik } from 'formik'
import { t as tt } from 'i18next'
import { type SurveyQuestion } from 'src/api'
import { staticAssert, type Interchangeable } from 'src/helpers/fns'
import { useTranslatable } from 'src/hooks/locale/utils'
import * as Yup from 'yup'
import type * as questionsPage from '../../hooks/questionsPage'
import SurveyCheckboxInput, { SurveyCheckboxSchema } from '../../inputs/SurveyCheckboxInput'
import SurveyCheckboxOtherInput, { SurveyCheckboxOtherSchema } from '../../inputs/SurveyCheckboxOtherInput'
import SurveyCoursesTextInput, { SurveyCoursesTextSchema } from '../../inputs/SurveyCoursesTextInput'
import SurveyDropdownInput, { SurveyDropdownSchema } from '../../inputs/SurveyDropdownInput'
import SurveyMultiCheckboxInput, { SurveyMultiCheckboxSchema } from '../../inputs/SurveyMultiCheckboxInput'
import SurveyMultiRadioInput, { SurveyMultiRadioSchema } from '../../inputs/SurveyMultiRadioInput'
import SurveyRadioInput, { SurveyRadioSchema } from '../../inputs/SurveyRadioInput'
import SurveyRadioOtherInput, { SurveyRadioOtherSchema } from '../../inputs/SurveyRadioOtherInput'
import SurveyTextInput, { SurveyStringSchema } from '../../inputs/SurveyTextInput'

interface Props {
  readonly page: number
  readonly perPage: number
  readonly data: readonly SurveyQuestion[]
  readonly progress: questionsPage.Return['progress']
  readonly updatePage: questionsPage.Return['updatePage']
  readonly onSubmit: questionsPage.Return['onSubmit']
  readonly isLoading: questionsPage.Return['isLoading']
}

export function Questions({ data, progress, page, perPage, updatePage, onSubmit, isLoading }: Props): JSX.Element {
  const t = useTranslatable()

  const initialValues = Object.fromEntries(
    data.map((question) => {
      return [`field-${question.id}`, question.userAnswer?.answer]
    })
  )

  const schema = Yup.object(
    Object.fromEntries(
      // eslint-disable-next-line array-callback-return
      data.map((question) => {
        const key = `field-${question.id}`
        const errorMessage = t('common:fill_in_the_required_field')

        switch (question.type) {
          case 'TEXT':
            return [key, SurveyStringSchema(errorMessage, question.required)]
          case 'CHECKBOXES':
            return [key, SurveyCheckboxSchema(errorMessage, question.required)]
          case 'CHECKBOXES_OTHER':
            return [key, SurveyCheckboxOtherSchema(errorMessage, question.required)]
          case 'RADIOS':
            return [key, SurveyRadioSchema(errorMessage, question.required)]
          case 'RADIOS_OTHER':
            return [key, SurveyRadioOtherSchema(errorMessage, question.required)]
          case 'MULTI_CHECKBOXES':
            return [key, SurveyMultiCheckboxSchema(errorMessage, question.required, question.options.length)]
          case 'MULTI_RADIOS':
            return [key, SurveyMultiRadioSchema(errorMessage, question.required, question.options.length)]
          case 'COURSES_CHECKBOXES':
            return [key, SurveyMultiCheckboxSchema(errorMessage, question.required, question.options.length)]
          case 'COURSES_RADIOS':
            return [key, SurveyMultiRadioSchema(errorMessage, question.required, question.options.length)]
          case 'COURSES_TEXTS':
            return [key, SurveyCoursesTextSchema(errorMessage, question.required, question.options.length)]
          case 'COURSES_DROPDOWNS':
            return [key, SurveyDropdownSchema(errorMessage, question.required, question.options.length)]
        }
      })
    )
  )

  return (
    <Formik initialValues={initialValues} validationSchema={schema} enableReinitialize onSubmit={onSubmit}>
      {(formik) => (
        <>
          <div
            style={{
              overflowY: 'auto',
              opacity: isLoading ? 0.5 : undefined,
              pointerEvents: isLoading ? 'none' : undefined,
            }}
            data-testid="questionsPage"
          >
            <div className="mb-[48px] mt-[24px] flex items-center justify-between text-primaryTextColor">
              <div className="mr-2 text-title" data-testid="progressText">
                {tt('survey:survey_progress', { progress: progress.progress })}
              </div>
              <div className="flex h-[8px] rounded-[3px] bg-primaryGray" style={{ width: '40%' }}>
                <div
                  className="rounded-[3px] bg-primaryGreen"
                  style={{ width: `${progress.progress}%` }}
                  data-testid="progressBar"
                />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} className="text-primaryTextColor">
              {data.map((question, i) => {
                const index = 1 + i + perPage * (page - 1)

                switch (question.type) {
                  case 'TEXT':
                    return (
                      <SurveyTextInput
                        key={question.id}
                        index={index}
                        name={`field-${question.id}`}
                        question={question as any}
                      />
                    )
                  case 'CHECKBOXES':
                    return (
                      <SurveyCheckboxInput
                        key={question.id}
                        index={index}
                        name={`field-${question.id}`}
                        question={question as any}
                      />
                    )
                  case 'CHECKBOXES_OTHER':
                    return (
                      <SurveyCheckboxOtherInput
                        key={question.id}
                        name={`field-${question.id}`}
                        question={question as any}
                        index={index}
                      />
                    )
                  case 'RADIOS':
                    return (
                      <SurveyRadioInput
                        key={question.id}
                        index={index}
                        name={`field-${question.id}`}
                        question={question as any}
                      />
                    )
                  case 'RADIOS_OTHER':
                    return (
                      <SurveyRadioOtherInput
                        key={question.id}
                        index={index}
                        name={`field-${question.id}`}
                        question={question as any}
                      />
                    )
                  case 'MULTI_CHECKBOXES':
                  case 'COURSES_CHECKBOXES':
                    return (
                      <SurveyMultiCheckboxInput
                        key={question.id}
                        index={index}
                        name={`field-${question.id}`}
                        question={question as any}
                      />
                    )
                  case 'COURSES_RADIOS':
                  case 'MULTI_RADIOS':
                    return (
                      <SurveyMultiRadioInput
                        key={question.id}
                        index={index}
                        name={`field-${question.id}`}
                        question={question as any}
                      />
                    )
                  case 'COURSES_TEXTS':
                    return (
                      <SurveyCoursesTextInput
                        key={question.id}
                        index={index}
                        name={`field-${question.id}`}
                        question={question as any}
                      />
                    )
                  case 'COURSES_DROPDOWNS':
                    return (
                      <SurveyDropdownInput
                        key={question.id}
                        index={index}
                        name={`field-${question.id}`}
                        question={question as any}
                      />
                    )
                  default:
                    staticAssert<Interchangeable<typeof question.type, never>>()
                    return null
                }
              })}
            </form>
          </div>
          <div className="flex justify-end border-t border-borderGrey py-1 text-primaryTextColor">
            {page > 1 && (
              <button
                onClick={() => void updatePage(page - 1)}
                className={classnames(
                  'mr-[8px] mt-[20px] h-[48px] cursor-pointer rounded-[4px] bg-primaryGray px-[20px] text-black hover:opacity-80',
                  {
                    disabled: isLoading,
                  }
                )}
                data-testid="previousQuestionButton"
              >
                {t('common:back')}
              </button>
            )}
            <button
              type="button"
              onClick={() => void formik.submitForm()}
              className={classnames(
                'mt-[20px] h-[48px] cursor-pointer rounded-[4px] bg-primaryBlue px-[20px] text-white hover:opacity-80',
                {
                  disabled: isLoading,
                }
              )}
              data-testid="nextQuestion"
            >
              {t('common:next')}
            </button>
          </div>
        </>
      )}
    </Formik>
  )
}
