import React from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createStateContext } from 'react-use'
import * as Api from 'src/api'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth, type AuthenticatedAuthState } from 'src/hooks/auth/auth'
import { useTranslatable } from 'src/hooks/locale/utils'

export type Page = 'start' | 'questions' | 'end'

export interface PageType {
  readonly type: Page
  readonly pageNumber?: number | null
}

export const [useSurveyModalState, SurveyModalStateProvider] = createStateContext(false)

export interface State {
  readonly active: Api.Survey | null
  readonly page: Page
  readonly questionsPageDefaultPage?: number
  readonly errors: any
  readonly isLoading: boolean
}

export interface Values {
  readonly [k: string]: readonly Api.UserAnswerOption[] | undefined
}

export interface Return extends State {
  readonly setPage: (page: Page) => void
  readonly onClose: () => Promise<void>
  readonly onFinish: () => Promise<void>
}

export function useSurvey(): Return {
  const auth = useAuth() as AuthenticatedAuthState
  const headers = useAuthenticatedHeaders()
  const userId = auth.user.id
  const profileId = auth.profile.id
  const t = useTranslatable()

  // TODO ეს ან მუშაბს ან არა
  const route = useLocation()

  const [state, setState] = React.useState<State>({
    questionsPageDefaultPage: undefined,
    active: null,
    errors: null,
    page: 'start',
    isLoading: false,
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalState, setModalState] = useSurveyModalState()
  const layoutState = React.useRef({
    layout: route.key,
    changes: 0,
  })

  if (layoutState.current.layout !== route.key) {
    layoutState.current.layout = route.key
    layoutState.current.changes++
  }

  React.useEffect(() => {
    setState((prev) => ({ ...prev, isLoading: true }))
    if (auth.profile?.type === 'UserProfileStudent') {
      const getStudentSurveyActive = async (): Promise<void> => {
        try {
          const data = await Api.getStudentSurveyActive({ headers })

          setState((prev) => ({ ...prev, active: data, isLoading: false }))

          if (layoutState.current.changes === 0 || layoutState.current.changes % data.intensityNum === 0) {
            setModalState(true)
          }
        } catch (error) {
          setState((prev) => ({ ...prev, errors: error, isLoading: false }))
        }
      }
      void getStudentSurveyActive()
    } else if (auth.profile?.type === 'UserProfileLecturer') {
      const getLecturerSurveyActive = async (): Promise<void> => {
        try {
          const data = await Api.getLecturerSurveyActive({ headers })

          setState((prev) => ({ ...prev, active: data, isLoading: false }))

          if (layoutState.current.changes === 0 || layoutState.current.changes % data.intensityNum === 0) {
            setModalState(true)
          }
        } catch (error) {
          setState((prev) => ({ ...prev, errors: error, isLoading: false }))
        }
      }
      void getLecturerSurveyActive()
    }
  }, [userId, profileId, setModalState, route.key, headers, auth.profile?.type])

  async function onClose(): Promise<void> {
    setState((prev) => ({ ...prev, isLoading: true }))

    try {
      if (auth.profile?.type === 'UserProfileStudent') {
        await Api.postStudentSurveyClose({
          headers,
          args: {
            id: state.active!.id,
          },
        })
      } else if (auth.profile?.type === 'UserProfileLecturer') {
        await Api.postLecturerSurveyClose({
          headers,
          args: {
            id: state.active!.id,
          },
        })
      }
    } catch (err) {
      toast.error(t('error:an_error_occurred'))
    } finally {
      setState((prev) => ({ ...prev, isLoading: false }))
    }

    setModalState(false)
  }
  async function onFinish(): Promise<void> {
    setState((prev) => ({ ...prev, isLoading: true }))
    try {
      if (auth.profile?.type === 'UserProfileStudent') {
        await Api.postStudentSurveyFinish({
          headers,
          args: {
            id: state.active!.id,
          },
        })
      } else if (auth.profile?.type === 'UserProfileLecturer') {
        await Api.postLecturerSurveyFinish({
          headers,
          args: {
            id: state.active!.id,
          },
        })
      }
    } catch (err) {
      console.error(err)
    } finally {
      setState((prev) => ({ ...prev, isLoading: false }))
    }

    setModalState(false)
  }

  function setPage(page: Page): void {
    setState((state) => {
      if (page === 'questions') {
        if (state.page === 'start') {
          return { ...state, questionsPageDefaultPage: undefined, page }
        } else {
          return {
            ...state,
            questionsPageDefaultPage: Math.ceil(state.active!.questionsCount / state.active!.perPage),
            page,
          }
        }
      }

      return { ...state, questionsPageDefaultPage: undefined, page }
    })
  }

  return {
    ...state,
    setPage,
    onClose,
    onFinish,
  }
}
