import { useFormikContext } from 'formik'
import React from 'react'
import * as Api from 'src/api'
import { UploadConfigMap } from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { type FileState } from 'src/hooks/fileUpload'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import PopoverComponent from 'src/views/components/PopoverComponent'
import { RegistrationFilesDownload } from 'src/views/components/RegistrationFilesDownload'
import DateInput from 'src/views/components/forms/formik/DateInput'
import FileInput from 'src/views/components/forms/formik/FileInput'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextInput from 'src/views/components/forms/formik/TextInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import Address from '../Components/Address'

export default function Step1(): React.ReactElement | null {
  const formik = useFormikContext<Api.postMasterRegistrationParams['body']>()
  const id = `label-${React.useId()}`
  const [persdocUid] = React.useState(`${id}-${Date.now()}`)
  const [photoId] = React.useState(`${id}-${Date.now()}`)
  const [armyStatusDocUid] = React.useState(`${id}-${Date.now()}`)

  const [documentCopyId] = React.useState(() => `${id}-${Date.now()}`)
  const [examCardCopyId] = React.useState(() => `${id}-${Date.now()}`)
  const [lecturerDocCopyId] = React.useState(() => `${id}-${Date.now()}`)
  const locale = useLocale()
  const t = useTranslatable()

  const maxDate = React.useMemo(() => {
    const currentDate = new Date()
    const resultDate = new Date()
    resultDate.setFullYear(currentDate.getFullYear() - 15)
    return resultDate.toString()
  }, [])

  const genderOptions = React.useMemo(() => {
    return [
      { label: t('person:male'), value: '1' },
      { label: t('person:female'), value: '2' },
    ]
  }, [t])

  const documentType = React.useMemo(() => {
    return [
      { label: t('resume:diploma'), value: '1' },
      { label: t('resume:certificate'), value: '2' },
    ]
  }, [t])

  const specialNeeds = React.useMemo(() => {
    return [
      { label: t('common:yes'), value: '1' },
      { label: t('common:no'), value: '2' },
    ]
  }, [t])

  const { data: countries, isValidating: countriesValidating } = useApi({
    endpoint: Api.getCountries,
    params: React.useMemo(
      () => ({
        headers: {
          'Accept-Language': locale,
        },
      }),
      [locale]
    ),
    suspense: false,
  })

  const { data: universities, isValidating: universitiesValidating } = useApi({
    endpoint: Api.getRegistrationUniversities,
    params: React.useMemo(
      () => ({
        headers: {
          'Accept-Language': locale,
        },
      }),
      [locale]
    ),
    suspense: false,
  })

  const fileInputChange = React.useCallback(
    (files: readonly FileState[], name: string) => {
      if (files[0]?.status === 'DONE') {
        void formik.setFieldValue(name, files[0]?.remoteFile.id)
      }
    },
    [formik]
  )

  return (
    <>
      <div className="mb-3 flex w-full flex-wrap justify-between">
        <div className="mb-3 mr-0 w-full min-w-[200px] md:!mb-0 md:mr-2 md:w-[49%]">
          <TextInput
            name="step1.pers_num"
            label={t('person:personal_number')}
            type="text"
            instructions={
              <PopoverComponent placement="right">
                <span className="text-primaryTextColor">{t('registration:personal_number')}</span>
              </PopoverComponent>
            }
            required
          />
        </div>
        <div className="w-full min-w-[200px] md:w-[49%]">
          <DateInput
            name="step1.birth_date"
            label={t('person:birth_date')}
            maxDate={maxDate}
            instructions={
              <div className="inline-flex">
                <PopoverComponent placement="right" labelClassName="">
                  <span className="text-primaryTextColor">{t('registration:birth_day')}</span>
                </PopoverComponent>
              </div>
            }
            required
          />
        </div>
      </div>
      <div className="mb-3 flex w-full flex-wrap justify-between">
        <div className="mb-3 mr-0 w-full min-w-[200px] md:!mb-0 md:mr-2 md:w-[49%]">
          <TextInput
            name="step1.firstname"
            label={t('registration:firstname_label')}
            type="text"
            instructions={
              <PopoverComponent placement="right">
                <span className="text-primaryTextColor">{t('registration:firstname')}</span>
              </PopoverComponent>
            }
            required
          />
        </div>
        <div className="w-full min-w-[200px] md:w-[49%]">
          <TextInput
            name="step1.lastname"
            label={t('registration:lastname_label')}
            type="text"
            instructions={
              <PopoverComponent placement="right">
                <span className="text-primaryTextColor">{t('registration:lastname')}</span>
              </PopoverComponent>
            }
            required
          />
        </div>
      </div>
      <div className="mb-3 flex w-full flex-wrap justify-between">
        <div className="mb-3 mr-0 w-full min-w-[200px] md:!mb-0 md:mr-2 md:w-[49%]">
          <TextInput
            name="step1.firstname_en"
            label={t('person:firstname_latin')}
            type="text"
            instructions={
              <PopoverComponent placement="right">
                <span className="text-primaryTextColor">{t('registration:firstname_latin')}</span>
              </PopoverComponent>
            }
            required
          />
        </div>
        <div className="w-full min-w-[200px] md:w-[49%]">
          <TextInput
            name="step1.lastname_en"
            label={t('person:lastname_latin')}
            type="text"
            instructions={
              <PopoverComponent placement="right">
                <span className="text-primaryTextColor">{t('registration:lastname_latin')}</span>
              </PopoverComponent>
            }
            required
          />
        </div>
      </div>
      <div className="flex w-full flex-wrap justify-between">
        <div className="mb-0 w-full min-w-[130px] md:w-[32%] xl:!mb-3">
          <TextInput
            name="step1.personal_email"
            label={t('person:personal_email')}
            type="email"
            instructions={
              <PopoverComponent placement="right">
                <span className="text-primaryTextColor">{t('registration:email')}</span>
              </PopoverComponent>
            }
            required
          />
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <DateInput
            name="step1.persdoc_exp_date"
            label={t('person:id_validation_date')}
            minDate={new Date().toString()}
            instructions={
              <div className="inline-flex">
                <PopoverComponent labelClassName="">
                  <span className="text-primaryTextColor">{t('registration:validation_date')}</span>
                </PopoverComponent>
              </div>
            }
            required
          />
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <FileInput
            Download={RegistrationFilesDownload}
            key={persdocUid}
            onChange={(files) => fileInputChange(files, 'step1.persdoc_uid')}
            config={UploadConfigMap.registration}
            label={t('registration:copy_id_card')}
            name="step1.persdoc_uid"
            instructions={t('registration:copy_id_card_instruction')}
            required
          />
        </div>
      </div>

      <div className="flex w-full flex-wrap justify-between">
        <div className="mb-0 w-full min-w-[130px] md:w-[32%] xl:!mb-3">
          <SelectInput name="step1.gender" options={genderOptions} label={t('person:gender')} required />
          {formik.values.step1.gender === '1' && (
            <FileInput
              Download={RegistrationFilesDownload}
              instructions={t('registration:military_registration_instructions')}
              key={armyStatusDocUid}
              onChange={(files) => fileInputChange(files, 'step1.army_status_doc_uid')}
              config={UploadConfigMap.registration}
              label={t('registration:military_registration')}
              name="step1.army_status_doc_uid"
              required
            />
          )}
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <SelectInput
            name="step1.citizenship"
            options={countries != null ? countries.map((s) => ({ label: s.name, value: s.id })) : []}
            label={t('person:citizenship')}
            disabled={countriesValidating}
            isLoading={countriesValidating}
            required
          />
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <FileInput
            Download={RegistrationFilesDownload}
            key={photoId}
            onChange={(files) => fileInputChange(files, 'step1.photo_uid')}
            config={UploadConfigMap.registrationPhoto}
            label={t('file:photo')}
            name="step1.photo_uid"
            required
          />
        </div>
      </div>

      <div className="mb-3 flex w-full flex-wrap justify-between">
        <div className="mb-3 mr-0 w-full min-w-[200px] md:!mb-0 md:mr-2 md:w-[49%]">
          <Address type="legal_address" />
        </div>
        <div className="w-full min-w-[200px] md:w-[49%]">
          <Address type="actual_address" />
        </div>
      </div>

      <div className="mb-3 flex w-full flex-wrap justify-between">
        <div className="mb-0 w-full min-w-[130px] md:w-[32%] xl:!mb-3">
          <TextInput
            name="step1.mobile_phone1"
            placeholder="(5__) __ __ __"
            label={`${t('registration:mobile_number')} 1`}
            type="tel"
            mask="(000) 00-00-00"
            instructions={
              <PopoverComponent placement="right">
                <span className="text-primaryTextColor">{t('registration:mobile')}</span>
              </PopoverComponent>
            }
            required
          />
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <TextInput
            name="step1.mobile_phone2"
            label={`${t('registration:mobile_number')} 2`}
            type="tel"
            placeholder="(5__) __ __ __"
            mask="(000) 00-00-00"
          />
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <TextInput
            name="step1.home_phone"
            label={t('registration:home_number')}
            type="tel"
            placeholder="(___) ___ __ __"
            mask="(000) 000-00-00"
          />
        </div>
      </div>

      <div className="mb-3 flex w-full flex-wrap justify-between">
        <div className="mb-0 w-full min-w-[130px] md:w-[32%] xl:!mb-3">
          <SelectInput
            name="step1.high_school"
            options={universities != null ? universities.map((s) => ({ label: s.name, value: s.id })) : []}
            label={t('resume:university')}
            disabled={universitiesValidating}
            isLoading={universitiesValidating}
            instructions={
              <PopoverComponent placement="right">
                <span className="text-primaryTextColor">{t('registration:higher_institution')}</span>
              </PopoverComponent>
            }
            required
          />
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <SelectInput
            name="step1.high_school_doc_type"
            options={documentType}
            label={t('file:select_document_type')}
            instructions={
              <PopoverComponent placement="right">
                <span className="text-primaryTextColor">{t('registration:document_type')}</span>
              </PopoverComponent>
            }
            required
          />
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <DateInput
            name="step1.high_school_finish_date"
            label={t('registration:graduation_date_end')}
            maxDate={new Date().toString()}
            instructions={
              <div className="inline-flex">
                <PopoverComponent placement="right" labelClassName="">
                  <span className="text-primaryTextColor">{t('registration:graduation_date')}</span>
                </PopoverComponent>
              </div>
            }
            required
          />
        </div>
      </div>

      <div className="mb-3 flex w-full flex-wrap justify-between">
        <div className="mb-0 w-full min-w-[130px] md:w-[32%] xl:!mb-3">
          <TextInput
            name="step1.high_school_doc_num"
            label={`${t('registration:document_number_label')}`}
            type="text"
            instructions={
              <PopoverComponent placement="right">
                <span className="text-primaryTextColor">{t('registration:document_number')}</span>
              </PopoverComponent>
            }
            required
          />
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <DateInput
            name="step1.high_school_doc_date"
            label={t('registration:issue_date_label')}
            maxDate={new Date().toString()}
            instructions={
              <div className="inline-flex">
                <PopoverComponent placement="right" labelClassName="">
                  <span className="text-primaryTextColor">{t('registration:issue_date')}</span>
                </PopoverComponent>
              </div>
            }
            required
          />
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <FileInput
            Download={RegistrationFilesDownload}
            key={documentCopyId}
            config={UploadConfigMap.registration}
            onChange={(files) => fileInputChange(files, 'step1.high_school_doc_uid')}
            label={t('registration:document_copy')}
            instructions={t('registration:document_copy_instructions')}
            name="step1.high_school_doc_uid"
            required
          />
        </div>
      </div>

      <div className="mb-3 flex w-full flex-wrap justify-between">
        <div className="mb-0 w-full min-w-[130px] md:w-[32%] xl:!mb-3">
          <TextInput
            name="step1.national_exam_id"
            label={t('registration:lecturer_exam_identity_code')}
            type="number"
            required
          />
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <FileInput
            Download={RegistrationFilesDownload}
            key={examCardCopyId}
            config={UploadConfigMap.registration}
            onChange={(files) => fileInputChange(files, 'step1.exam_pass_doc_uid')}
            label={t('registration:lecturer_document_copy')}
            instructions={t('registration:lecturer_document_copy_instructions')}
            name="step1.exam_pass_doc_uid"
            required
          />
        </div>
        <div className="w-full min-w-[130px] md:w-[32%]">
          <FileInput
            Download={RegistrationFilesDownload}
            key={lecturerDocCopyId}
            config={UploadConfigMap.registration}
            onChange={(files) => fileInputChange(files, 'step1.exam_card_uid')}
            label={t('registration:lecturer_certificate_copy')}
            instructions={t('registration:lecturer_certificate_copy_instructions')}
            name="step1.exam_card_uid"
          />
        </div>
      </div>

      <div className="mb-3 flex w-full flex-wrap justify-between">
        <div className="mb-3 mr-0 w-full min-w-[200px] md:!mb-0 md:mr-2 md:w-[49%]">
          <SelectInput
            name="step1.has_limited_abilities"
            options={specialNeeds}
            label={`${t('registration:special_needs')}`}
            instructions={
              <PopoverComponent placement="right">
                <span className="text-primaryTextColor">{t('registration:special_needs_instruction')}</span>
              </PopoverComponent>
            }
            required
          />
        </div>
        {formik.values.step1.has_limited_abilities === '1' && (
          <div className="w-full min-w-[200px] md:w-[49%]">
            <TextareaInput
              name="step1.limited_abilities_text"
              label={t(`common:detailed_information`)}
              rows={3}
              instructions={
                <PopoverComponent placement="right">
                  <span className="text-primaryTextColor">{t(`registration:special_needs_text_instructions`)}</span>
                </PopoverComponent>
              }
              required
            />
          </div>
        )}
      </div>
    </>
  )
}
