import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { type UserAnswerOption } from 'src/api'
import * as SurveyTable from 'src/tailwind/components/SurveyTable'
import * as Yup from 'yup'

interface Option {
  readonly id: number
  readonly title: string // გამოდის ჩეკბოქსის ლეიბლად
  readonly customAllowed: boolean
  readonly fcolor: string
  readonly bcolor: string
}

interface Criterias {
  readonly id: number
  readonly title: string // გამოდის თავზე ჰორიზონტალურ ხაზში
  readonly fcolor: string
  readonly bcolor: string
}

interface CoursesTextsType {
  readonly id: number
  readonly type: number
  readonly title: string
  readonly required: boolean
  readonly fcolor: string
  readonly bcolor: string
  readonly options: readonly Option[]
  readonly criterias: readonly Criterias[]
}

interface checkCriterias {
  readonly criteriaId: number
  readonly value: string
}
interface QuestionTypeCoursesTextsValue {
  readonly optionId: number
  readonly criterias: readonly checkCriterias[]
}

interface TypeCoursesTextsProps {
  readonly question: CoursesTextsType
  readonly name: string
  readonly index: number
}

export function SurveyCoursesTextSchema(message: string, isRequired: boolean, optionsLength: number): Yup.Schema {
  if (!isRequired) return Yup.mixed()
  return Yup.array(Yup.object()).min(optionsLength, message).required(message)
}

export default function SurveyCoursesTextInput({ question, name, index }: TypeCoursesTextsProps): React.ReactElement {
  const { errors, isValid, submitCount } = useFormikContext<{ [k: string]: readonly UserAnswerOption[] | undefined }>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<undefined | readonly QuestionTypeCoursesTextsValue[]>({ name })

  const values = React.useRef(
    React.useMemo(() => {
      return Object.fromEntries(
        question.options.flatMap((o) => {
          return question.criterias.map((c) => {
            return [
              `input-${o.id}-${c.id}`,
              field.value?.find((v) => v.optionId === o.id)?.criterias.find((v) => v.criteriaId === c.id)?.value ?? '',
            ]
          })
        })
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  function updateValue(): void {
    const data = question.options.flatMap((option) => {
      const criterias = question.criterias.flatMap((c) => {
        const value = values.current[`input-${option.id}-${c.id}`]!

        if (value !== '') {
          return {
            criteriaId: c.id,
            value,
          }
        }

        return []
      })

      if (criterias.length > 0) {
        return {
          optionId: option.id,
          criterias,
        }
      }

      return []
    })

    void helpers.setValue(data)
  }

  return (
    <div data-testid={name}>
      <div className="flex" data-testid="questionTitle">
        <div className="mr-1">{index}.</div>
        <div className="font-bold">
          {question.title}
          {question.required && <span className="ml-1 text-primaryRed">*</span>}
        </div>
      </div>
      <SurveyTable.SurveyTable>
        <SurveyTable.Tbody>
          <SurveyTable.Tr>
            <SurveyTable.Th />
            {question.criterias.map((criteria) => (
              <SurveyTable.Th key={criteria.id} data-testid={`coursesTextCriteriaTitle/${criteria.id}`}>
                {criteria.title}
              </SurveyTable.Th>
            ))}
          </SurveyTable.Tr>
          {question.options.map((option) => (
            <SurveyTable.Tr key={option.id} data-testid={`coursesTextOption/${option.id}`}>
              <SurveyTable.Td data-testid="optionTitle">{option.title}</SurveyTable.Td>
              {question.criterias.map((c) => (
                <SurveyTable.Td key={c.id} style={{ padding: 10 }} data-testid={`coursesTextCriteria/${c.id}`}>
                  <div className="text-center">
                    <input
                      className="rounded-[4px] border border-borderColor py-[8px] pl-[13px] dark:bg-[#323232]"
                      type="text"
                      value={values.current[`input-${option.id}-${c.id}`]}
                      onChange={(e) => {
                        values.current[`input-${option.id}-${c.id}`] = e.target.value
                        updateValue()
                      }}
                    />
                  </div>
                </SurveyTable.Td>
              ))}
            </SurveyTable.Tr>
          ))}
        </SurveyTable.Tbody>
      </SurveyTable.SurveyTable>
      <div className="h-[30px] px-[12px] py-0 text-sm text-primaryRed" data-testid="error">
        {!isValid && submitCount > 0 ? errors[`field-${question.id}`] : null}
      </div>
    </div>
  )
}
