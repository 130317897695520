import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { type UserAnswerOption } from 'src/api'
import { useTheme } from 'src/state/providers/Theme'
import * as SurveyTable from 'src/tailwind/components/SurveyTable'
import * as Yup from 'yup'

interface Option {
  readonly id: number
  readonly title: string // გამოდის ჩეკბოქსის ლეიბლად
  readonly customAllowed: boolean
  readonly fcolor: string
  readonly bcolor: string
}

interface RadioOtherType {
  readonly id: number
  readonly type: number
  readonly title: string
  readonly required: boolean
  readonly fcolor: string
  readonly bcolor: string
  readonly options: Option[]
}

interface QuestionTypeRadioOtherTypeValue {
  readonly optionId: number
  readonly value: string | null
}

interface TypeRadioOtherTypeProps {
  readonly question: RadioOtherType
  readonly name: string
  readonly index: number
}

export function SurveyRadioOtherSchema(message: string, isRequired: boolean): Yup.Schema {
  if (!isRequired) return Yup.mixed()

  return Yup.array(
    Yup.object({
      value: Yup.lazy((value) => {
        if (value === null) {
          return Yup.mixed().nullable()
        }
        if (value === undefined) {
          return Yup.mixed()
        }

        return Yup.string().required(message)
      }),
    })
  )
    .min(1, message)
    .required(message)
    .transform((val) => {
      const result = val.map((o: any) => {
        if ('value' in o) {
          if (o.value === undefined) {
            return { ...o, value: '' }
          }
        }
        return o
      })
      return result
    })
}

export default function SurveyRadioOtherInput({ name, question, index }: TypeRadioOtherTypeProps): React.ReactElement {
  const { errors, isValid, submitCount } = useFormikContext<{ [k: string]: readonly UserAnswerOption[] | undefined }>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<undefined | readonly QuestionTypeRadioOtherTypeValue[]>({ name })
  const theme = useTheme()

  const labelPrefix = `label-${React.useId()}`

  const value = React.useRef(
    React.useMemo(() => {
      return {
        checked: field.value?.[0]?.optionId ?? null,
        text: field.value?.[0]?.value ?? null,
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  function updateValue(): void {
    if (value.current.checked == null) {
      void helpers.setValue([])
    } else {
      void helpers.setValue([
        {
          optionId: value.current.checked,
          value: value.current.text,
        },
      ])
    }
  }

  return (
    <div data-testid={name}>
      <div className="flex" data-testid="questionTitle">
        <div className="mr-1">{index}.</div>
        <div className="font-bold">
          {question.title}
          {question.required && <span className="ml-1 text-primaryRed">*</span>}
        </div>
      </div>
      <SurveyTable.SurveyTable className="border-0">
        <SurveyTable.Thead />
        <SurveyTable.Tbody>
          {question.options.map((option) => (
            <SurveyTable.Tr key={option.id} data-testid={`radioOtherOption/${option.id}`}>
              <SurveyTable.Td>
                <div
                  className="relative flex items-center"
                  style={{
                    color: `${option.fcolor ?? (theme === 'dark' ? '#e5e5e5' : 'initial')}`,
                    backgroundColor: `${option.bcolor ?? 'initial'}`,
                  }}
                >
                  <input
                    className="flex items-center"
                    id={`${labelPrefix}-${option.id}`}
                    name={`${labelPrefix}-${option.id}`}
                    type="radio"
                    value={`${labelPrefix}-${option.id}`}
                    checked={value.current.checked === option.id}
                    onChange={() => {
                      if (option.customAllowed) {
                        value.current.text = ''
                      } else {
                        value.current.text = null
                      }

                      value.current.checked = option.id
                      updateValue()
                    }}
                  />
                  <label htmlFor={`${labelPrefix}-${option.id}`} style={{ marginLeft: 10, marginTop: '1.4px' }}>
                    {option.title}
                  </label>
                  {option.customAllowed ? (
                    <input
                      type="text"
                      className="w-full rounded-[4px] border border-borderColor py-[8px] pl-[13px]"
                      value={value.current.text ?? ''}
                      onChange={(e) => {
                        value.current.text = e.target.value
                        updateValue()
                      }}
                      disabled={value.current.checked !== option.id}
                      style={{
                        maxWidth: 320,
                        marginLeft: 20,
                      }}
                    />
                  ) : null}
                </div>
              </SurveyTable.Td>
            </SurveyTable.Tr>
          ))}
        </SurveyTable.Tbody>
      </SurveyTable.SurveyTable>
      <div className="h-[30px] px-[12px] py-0 text-sm text-primaryRed" data-testid="error">
        {!Array.isArray(errors[`field-${question.id}`]) && submitCount > 0 && !isValid
          ? errors[`field-${question.id}`]
          : Array.isArray(errors[`field-${question.id}`]) && submitCount > 0 && !isValid
            ? (errors[`field-${question.id}`] as any)[(errors[`field-${question.id}`] as any).length - 1].value
            : null}
      </div>
    </div>
  )
}
