import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SurveyIcon } from 'src/assets/icons/customIcons/header-icons/Survey'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import { Modal } from 'src/tailwind/components/Modal'
import { useSurvey, useSurveyModalState } from '../hooks/survey'
import { EndPage } from './pages/EndPage'
import { QuestionsPage } from './pages/QuestionsPage'
import { StartPage } from './pages/StartPage'

export function Survey(): JSX.Element | null {
  const userState = useUserState()

  if (userState.data?.surveyExists !== true) return null

  return <SurveyModal />
}

export function SurveyModal(): JSX.Element | null {
  const state = useSurvey()

  const [modalState] = useSurveyModalState()

  if (state.active == null) return null

  return (
    <Modal
      title={state.active.title}
      icon={
        <FontAwesomeIcon
          icon={ProRegularSvgIcons.faChartBar}
          style={{ fontSize: '1.25rem' }}
          className="text-svgColor"
        />
      }
      isModalOpen={modalState}
      onClose={state.onClose}
      testId="surveyModal"
    >
      {state.page === 'start' && (
        <StartPage isLoading={state.isLoading} survey={state.active} setPage={state.setPage} />
      )}
      {state.page === 'questions' && (
        <QuestionsPage survey={state.active} setPage={state.setPage} defaultPage={state.questionsPageDefaultPage} />
      )}
      {state.page === 'end' && (
        <EndPage isLoading={state.isLoading} survey={state.active} setPage={state.setPage} onFinish={state.onFinish} />
      )}
    </Modal>
  )
}

export function SurveyButton(): JSX.Element | null {
  const t = useTranslatable()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useSurveyModalState()
  const userState = useUserState()

  if (userState.data?.surveyExists !== true || userState.data.regime.functionalIsLimited) return null

  return (
    <a
      className="relative cursor-pointer rounded-full p-[3px] hover:bg-hover-icon"
      role="button"
      onClick={() => setState(true)}
      title={t('survey:survey')}
      data-testid="surveyButton"
    >
      <SurveyIcon />
      {/* <small className='d-lg-none ml-1'>{t('survey:survey')}</small> */}
    </a>
  )
}
