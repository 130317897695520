import { LoudspeakerWhiteIcon } from 'src/assets/icons/customIcons/LoudspeakerWhite'
import { useAuth } from 'src/hooks/auth/auth'
import { useUserState } from 'src/hooks/userState'

export function Alert(): JSX.Element | null {
  const auth = useAuth()
  const userState = useUserState()

  if (auth.state !== 'Authenticated') return null

  const profileStatus = auth.profile.profileStatus?.active === false && (
    <div
      className="mb-[20px] flex items-center justify-center rounded-card bg-darkblueMessages px-4 py-3 print:hidden"
      data-testid="profileStatus"
    >
      <div className="flex min-h-[48px] min-w-[48px] items-center justify-center rounded-full bg-[#4e536b]">
        <LoudspeakerWhiteIcon />
      </div>
      <span className="mx-6 w-full text-white">{auth.profile.profileStatus.message}</span>
      {/* <div className=' rounded-full min-h-[48px] min-w-[48px] flex justify-center items-center hover:bg-[#4e536b] cursor-pointer'>
        <CloseIcon />
      </div> */}
    </div>
  )
  const riskGroups = userState.data?.riskGroups
  const riskGroupMessages = riskGroups != null && riskGroups.length > 0 && (
    <div
      className="mb-[20px] flex items-center justify-center rounded-card bg-lightRed px-4 py-3 print:hidden"
      data-testid="riskGroups"
    >
      <div className="flex min-h-[48px] min-w-[48px] items-center justify-center rounded-full bg-primaryRed">
        <LoudspeakerWhiteIcon />
      </div>
      <span className="dangerouslySetInnerHTML mx-6 w-full text-primaryRed dark:text-white">
        {riskGroups.map((group) => (
          <div key={group.id} className="my-2">
            <div dangerouslySetInnerHTML={{ __html: group.message }} />
          </div>
        ))}
      </span>
    </div>
  )

  return (
    <>
      {riskGroupMessages}
      {profileStatus}
    </>
  )
}
