import classnames from 'classnames'
import { type Survey } from 'src/api'
import { useTranslatable } from 'src/hooks/locale/utils'
import type * as survey from '../../hooks/survey'

interface Props {
  readonly isLoading: boolean
  readonly setPage: survey.Return['setPage']
  readonly onFinish: survey.Return['onFinish']
  readonly survey: Survey
}
export const EndPage = ({ setPage, onFinish, survey, isLoading }: Props): JSX.Element => {
  const t = useTranslatable()

  return (
    <>
      <div
        style={{
          overflowY: 'auto',
          opacity: isLoading ? 0.5 : undefined,
          pointerEvents: isLoading ? 'none' : undefined,
        }}
      >
        <div
          className="flex items-center text-primaryTextColor"
          data-testid="finishText"
          dangerouslySetInnerHTML={{ __html: survey.finishText }}
        />
      </div>
      <div className="flex justify-end border-t border-borderGrey py-1">
        <button
          onClick={() => void setPage('questions')}
          className={classnames(
            'mr-[8px] mt-[20px] h-[48px] cursor-pointer rounded-[4px] bg-primaryGray px-[20px] text-black hover:opacity-80',
            {
              disabled: isLoading,
            }
          )}
          data-testid="backButton"
        >
          {t('common:back')}
        </button>
        <button
          type="button"
          className={classnames(
            'mt-[20px] h-[48px] cursor-pointer rounded-[4px] bg-primaryBlue px-[20px] text-white hover:opacity-80',
            {
              disabled: isLoading,
            }
          )}
          onClick={() => void onFinish()}
          data-testid="finishButton"
        >
          {t('common:submit')}
        </button>
      </div>
    </>
  )
}
